import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Modal from 'components/Shared/Modal';
import AnnuitiesPricingModelSelectionContainer from 'components/AnnuitiesStartPage/AnnuitiesPricingModelSelectionContainer';
import Creators from 'actions/CriteriaActions';
import * as odaTracking from 'utilities/odaTracking';
import * as adobeAnalytics from 'utilities/adobeAnalytics';

class AnnuitiesProductLink extends Component {
  constructor() {
    super();
    this.state = { isModalOpen: false };
  }
  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };
  handleLinkClicked = event => {
    const pricingModels = _.get(this.props.plan, ['pricingModels'], []);
    const pricingModel = _.first(pricingModels);
    if (pricingModels.length === 1) {
      // don't bother opening model if there is only one choice
      this.props.onPricingModelSelection(pricingModel.policyCode);
    } else {
      this.handleOpenModal();
    }
    this.props.trackAnnuityOption(this.props.plan);
  };
  _renderStatus = pricingModel => {
    const status = pricingModel.policyStatus || '--';
    const indicator = status.toUpperCase();
    const isOpen = status === 'Open';
    const statusDescription = isOpen ? 'Open to new customers' : 'Closed to new customers';
    return (
      <span>
        <span className={`${indicator.toLowerCase()} indicator`}>{indicator}</span>
        {statusDescription}
      </span>
    );
  };
  _renderSelections = () => {
    const pricingModels = _.get(this.props.plan, ['pricingModels'], []);
    return _.map(pricingModels, pricingModel => {
      const selectionAction = () => {
        this.handleCloseModal();
        if(pricingModel.policyCode !== "PV190" && pricingModel.policyCode !== "PV120" && pricingModel.policyCode !== "PI220"){
          this.props.onPricingModelSelection(pricingModel.policyCode);
        }
        
      };
      return (
        <AnnuitiesPricingModelSelectionContainer
          pricingModel={pricingModel}
          onClick={selectionAction}
          key={pricingModel.policyCode}
        />
      );
    });
  };
  //update url
  render() {
    const { plan } = this.props;
    const selections = this.state.isModalOpen ? this._renderSelections() : null;
    const domain = "fundresearch.fidelity.com"; //QA link does not exist, : window.location.hostname.includes('xq1') ? "fundresearchqa1.fmr.com" : "fundresearch.fidelity.com";
    const envSubDomain = () => { 
      if (window.location.hostname.includes('xq1')){
        return 'oltxxq1'
      } else if (window.location.hostname.includes('xq2')){
        return 'oltxxq2'
      } else {
        return 'oltx'
      }
    }

    return (
      <span>
        {plan.id === "Find Rates for Your State of Residence"
          ? <a href={plan.link} dangerouslySetInnerHTML={{ __html: plan.name }}></a>
          : plan.id === "New York Life Premier Variable Annuity - P Series" 
          ? <a href={"https://fundresearch.fidelity.com/annuities/summary/FPVAC"} dangerouslySetInnerHTML={{ __html: plan.name }}></a>
          : plan.id === "Nationwide Personal Income<sup>SM</sup> Annuity" 
          ? <a href={`https://${domain}/annuities/summary/FONWC`} dangerouslySetInnerHTML={{ __html: plan.name }}></a>
          :<a onClick={this.handleLinkClicked} dangerouslySetInnerHTML={{ __html: plan.name }}></a>
        }
        <Modal
          isOpen={this.state.isModalOpen}
          handleCloseModal={this.handleCloseModal}
          classNames="annuities-product-modal"
        >
          <h2 className="modal-title" dangerouslySetInnerHTML={{ __html: `${plan.name} - Available Contracts` }} />
          <div className="pricing-model-selections-container">{selections}</div>
          <span className="go-to-positions-link">
            Need help?{' '}
            <a href={`https://${envSubDomain()}.fidelity.com/ftgw/fbc/oftop/portfolio#positions`}>
              Go to your positions
            </a>{' '}
            and click on the Fund Performance link.
          </span>
        </Modal>
      </span>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    trackAnnuityOption: product => {
      const trackingData = odaTracking.getAnnuityOptionTrackingData(product);
      dispatch(Creators.odaLogAction(trackingData));
      const adobeTrackingData = adobeAnalytics.getAnnuityOptionTrackingData(product.name);
      dispatch(Creators.trackAdobeAnalytics(adobeTrackingData));
    },
  };
};

AnnuitiesProductLink.propTypes = {
  plan: PropTypes.object.isRequired,
  onPricingModelSelection: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(AnnuitiesProductLink);
